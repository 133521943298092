import React from "react"
import Seo from "../../../components/seo"
import Layout from "../../../app/layout"
import { Link, graphql } from "gatsby"

import { Breadcrumb } from "antd"
import { ChevronRight } from "../../../components/Icon"

import Container from "../../../components/Container"
import Masthead from "../../../components/Masthead"
import { useI18next } from "gatsby-plugin-react-i18next"

const AlumniPage = ({
  data: {
    awardeeAlumni: data = {
      page_setting: {},
    },
  },
}) => {
  const { t } = useI18next()
  return (
    <Layout>
      <Seo title={data.page_setting.header_title} />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.page_setting.header_title}</Breadcrumb.Item>
          <Breadcrumb.Item>{data.page_setting.header_title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={
          data.page_setting.header_video_thumbnail ||
          data.page_setting.header_image
        }
        title={data.page_setting.header_title}
        useVideo={!!data.page_setting.header_use_video}
        videoId={data.page_setting.header_video_url}
        textBottom={t("Selengkapnya")}
      />

      <div className="section section-secondary padding-bottom-none text-medium">
        <div className="overlap-section-left align-middle">
          <div className="overlap-section-col overlap-section-col-img">
            <img
              src={data.page_setting.contribution_image}
              alt={data.page_setting.contribution_title}
              className="overlap-bottom"
            />
          </div>
          <div className="overlap-section-col overlap-section-col-content">
            <h2 className="section-title">
              {data.page_setting.contribution_title}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.contribution_description,
              }}
            />
          </div>
        </div>
      </div>

      <div className="section text-medium">
        <div
          className="overlap-section-right align-middle text-medium"
          style={{ marginTop: 48 }}
        >
          <div className="overlap-section-col overlap-section-col-img">
            <img
              src={data.page_setting.welcoming_alumni_image}
              alt={data.page_setting.welcoming_alumni_title}
              width="100%"
            />
          </div>
          <div className="overlap-section-col overlap-section-col-content">
            <h2 className="section-title">
              {data.page_setting.welcoming_alumni_title}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.welcoming_alumni_description,
              }}
            />
          </div>
        </div>

        <div className="section section-secondary padding-bottom-none text-medium">
          <div className="overlap-section-left align-middle">
            <div className="overlap-section-col overlap-section-col-img">
              <img
                src={data.page_setting.alumni_management_image}
                alt={data.page_setting.alumni_management_title}
                className="overlap-bottom"
              />
            </div>
            <div className="overlap-section-col overlap-section-col-content">
              <h2 className="section-title">
                {data.page_setting.alumni_management_title}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.alumni_management_description,
                }}
              />
            </div>
          </div>
        </div>

        {/* <Container>
          <Row gutter={80} style={{ margin: "0 -15px" }}>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <h2 className="section-title">
                {data.page_setting.welcoming_alumni_title}
              </h2>
            </Col>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.welcoming_alumni_description,
                }}
              />
            </Col>
          </Row>
        </Container> */}

        {/* <div
          className="overlap-section-right align-middle text-medium"
          style={{ marginTop: 48 }}
        >
          <div className="overlap-section-col overlap-section-col-img">
            <img
              src={data.page_setting.alumni_management_image}
              alt={data.page_setting.alumni_management_title}
              width="100%"
            />
          </div>
          <div className="overlap-section-col overlap-section-col-content">
            <h2 className="section-title">
              {data.page_setting.alumni_management_title}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.alumni_management_description,
              }}
            />
          </div>
        </div> */}
      </div>
    </Layout>
  )
}
export const query = graphql`
  query awardeeAlumniQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    awardeeAlumni(lang: { eq: $language }) {
      page_setting {
        alumni_management_description
        alumni_management_image
        alumni_management_title
        contribution_description
        contribution_image
        contribution_title
        header_image
        header_title
        header_use_video
        header_video_thumbnail
        header_video_url
        slug
        welcoming_alumni_description
        welcoming_alumni_title
        welcoming_alumni_image
      }
    }
  }
`
export default AlumniPage
